.phoneInput > input {
  display: none;
  width: 80px;
  border: none;
}
.phoneInput {
  width: 50px !important;
}

.profile-photo {
  border-radius: 10px;
}
.bTn {
  background-color: #0b43f0;
  cursor: pointer;
  color: #ffff;
}
.bTn:hover {
  background-color: #0b44f0b5;
  color: #ffff;
}
.ebsj {
  background-color: #24c452ce;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.search-input {
  height: 40px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 5px;
  margin-left: 6px;
  padding: 5px;
}
.search-container {
  position: relative;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #555;
  cursor: pointer;
}
.range-picker-input {
  height: 55px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 10px;
  padding: 3px;
  background-color: #fff;
  /* opacity: 0.6; */
  margin-right: 15px;
}

.report-modal .modal-dialog {
  max-width: 800px !important;
}

.incidence-details :hover {
  background-color: #2fc477;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.incidence-details {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.complaint-image img {
  min-width: 120px;
}
.image-view-modal div {
  height: 700px;
  width: 80vw;
}
.image-view-modal img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  max-width: none;
}
.open-status {
  border-left: none !important;
}
.process-status {
  border-left: 3px solid gainsboro !important;
}
.location-icon {
  background: #3e4954;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  border-radius: 56px;
}
.even-row {
  background-color: rgba(47, 76, 221, 0.2);
}
/* .page-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  border-radius: 0.5rem;
  padding: 0.9375rem 1.875rem;
  margin-bottom: 1.875rem;
} */
.page-title {
  position: static;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: saturate(200%) blur(1.875rem) !important;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* z-index: 1100; */
  border-radius: 0.5rem;
  padding: 0.9375rem 1.875rem;
  margin-bottom: 1.875rem;
  z-index: 1100;
  left: auto;
  right: 0px;
}
.page-title span {
  color: #0b43f0;
  font-weight: 700;
}
.page-title p {
  /* color: #0B43F0; */
  font-weight: 450;
  font-size: 18px;
  color: gray;
}
.acton-icons {
  transition: all 0.3s;
}
.acton-icons:hover {
  transform: scale(1.3);
}
.nav-tabs {
  border-bottom: 1px solid #423e3e5c !important;
}

.tab-content {
  min-height: 450px;
}
.tab-section {
  padding: 0.5rem 2rem !important;
  font-size: 18px;
  font-weight: 500;
}
.tab-section.active {
  background-color: rgba(47, 76, 221, 0.2) !important;
  color: #0b43f0 !important;
  border-color: #423e3e5c #423e3e5c #fbfbfb !important;
}
.post-card {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem !important;
  backdrop-filter: saturate(200%) blur(1.875rem) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.video-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  max-width: 250px;
  background-image: url("https://ggayane.github.io/css-experiments/cards/dark_rider-cover.jpg");
  background-size: cover;
  border-radius: 10px;
}

.wrapper {
  height: 350px;
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .wrapper h5,
p {
  color: white;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
} */
.offer-action-btn {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  color: #fff;
  transition: calc(0.3s);
  border: none !important;
  padding: 0 !important;
}
.list-btn {
  border: none;
  height: 30px;
  width: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: calc(0.3s);
}
.list-btn:hover {
  border: none;
  transform: scale(1.1);
}
.offer-action-btn:hover {
  transform: scale(1.2);
}
.dropdown-toggle::after {
  display: none !important;
}
.offer-acton-options {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  gap: 10px;
}

.title {
  width: 100%;
  transition: transform 0.5s;
}
.video-card:hover .title {
  transform: translate3d(0%, -50px, 100px);
}
.image-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.view-list-icon {
  height: 27px;
  width: 27px;
  transition: all 0.3s;
}
.view-list-icon:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.filter-tabs {
  display: flex;
  align-items: center;

  background-color: rgba(47, 76, 221, 0.2);
  padding: 5px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.tab {
  margin: 0;
  color: #0b43f0;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  width: 90px;
  text-align: center;
  cursor: pointer;
}
.active-tab {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  margin: 0;
  width: 90px;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out;
  /* transform: translateX(0.1s); */
}

.back-btn {
  background-color: #d8e6ff;
  color: #000;
  transition: calc(0.3s);
}
.back-btn:hover {
  background-color: rgb(211, 211, 211);
  color: #000;
  transform: scale(1.1);
}
.custom-control-label:before {
  top: -12px !important;
}
.custom-control-label:after {
  top: -12px !important;
}

.add-exercises-modal .modal-dialog {
  min-width: 590px;
}
.exercises-form-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-height: 200px;
  overflow: scroll;
}
.exercises-form-details > div {
  min-width: 180px;
}
.add-input-button .svg-inline--fa {
  font-size: 30px;
  color: #0b43f0;
  transition: all 0.3s;
}
.add-input-button .svg-inline--fa:hover {
  color: #0b43f0;
  transform: scale(1.1);
}
.scroll-div {
  max-height: 40rem;
  overflow-x: auto;
}
.remove-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 25px;
  border: 1px solid gray;
  font-size: 20px;
  right: 0px;
  transition: all 0.3s;
}
.remove-btn:hover {
  transform: scale(1.1);
  color: red;

  /* border: 1px groove #ff5872; */
}
.reel-modal .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh;
  margin: 0;
}
.back-btn-reel {
  position: absolute;
  left: 1px;
  padding: 2rem;
}
.back-btn-reel button {
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border: 1px solid gray;
  transition: all 0.3s;
}
.back-btn-reel button:hover {
  transform: scale(1.1);
}
