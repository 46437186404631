.user-details-modal .modal-dialog {
  /* min-width: 300px !important; */
  width: 300px !important;
}

.profile-card {
  width: 300px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Arial", sans-serif;
  position: relative;
}

.image-header {
  background-color: #0b43f0;
  padding: 50px;
}

.profile-image {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  left: 31%;
}

.content {
  padding: 20px;
  padding-top: 66px;
}

.content h2 {
  margin: 10px 0;
}

.content p {
  color: gray;
  margin-bottom: 20px;
}

.social-links {
  margin-bottom: 20px;
}

.social-link {
  margin: 0 5px;
  color: #4267b2;
  font-size: 20px;
  text-decoration: none;
}

.actions {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions .badge {
  padding: 10px 20px;
}

.subscribe-button {
  color: #0b43f0;
  background-color: rgba(47, 76, 221, 0.2);
  padding: 8px;
  border-radius: 5px;
  font-weight: 550;
  font-size: 16px;
  margin: 0;
}

.stats {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #eee;
  color: gray;
}

.stats span {
  font-size: 14px;
  font-weight: 500;
}
