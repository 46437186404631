.form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  width: 100%;
  gap: 20px;
  height: 50vh;
}
.second-section {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}
.second-section > div {
  flex-grow: 1;
  width: 50%;
}
.left-side {
  width: 35%;
  border: 1px solid gray;
  height: 55%;
  background-color: #fff;
  border-radius: 20px;
}

.right-side {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
}

.preview-image {
  display: block;
  padding: 5px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

label,
input,
select {
  display: block;
  margin-bottom: 10px;
}
label {
  margin: 0;
  margin-top: 10px;
}

input[type="file"] {
  margin-top: 5px;
}

.remove-btn {
  position: absolute;
  right: -12px;
  top: -10px;
}
.remove-btn button {
  height: 25px;
  width: 25px;
  border: 1px solid gray;
  border-radius: 50%;
  transition: all 0.3s;
}
.remove-btn button:hover {
  transform: scale(1.2);
  color: red;
  background-color: #ffeae6;
}
.dropdown-content {
}
.select-panel .options {
  /* height: 700px !important; */
  margin-bottom: 2rem !important;
  height: 200px !important;
}
.item-renderer span {
  margin-left: 10px !important;
}
