.reel-card {
  position: relative;
  overflow: hidden;
  height: 215px;
  width: 383px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.user-info {
  display: flex;
  align-items: start;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  width: 100%;
  border-radius: 0px 0px 10px 10px;
}

.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.username {
  font-weight: bold;
  color: #fff;
}

.caption {
  color: white;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  word-break: break-all;
}

.option-icon {
  position: absolute;
  color: #ffff;
  display: flex;
  justify-content: end;
  width: 100%;
  top: 0;
  /* padding: 10px; */
}
.icon {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
  border-radius: 5px;
}

.icon:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px 0px;
}

.option-icon .btn-primary.dropdown-toggle {
  color: #fff !important;
  padding: 0.938rem !important;
  padding-bottom: 0px !important;
}
.option-icon .dropdown-menu {
  left: -4px !important;
  padding: 0.2rem !important;
}
.option-icon .dropdown-item {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  padding: 0.2rem 0.5rem !important;
}

/* //=====================Reel modal show Css ================================================================= */
.reel-modal {
  background-color: white;
}
@media (min-width: 576px) {
  .reel-modal .modal-dialog {
    /* max-width: 385px; */
    margin: 1.75rem auto;
    border-radius: 10px;
  }
}

.reel-show-card {
  position: relative;
  width: 385px;
  height: 684px;
}
.reel-modal .modal-content {
  height: 100%;
}
.reel-show-card > video {
  border-radius: 6px;
  background-color: black;
  width: 100%;
  height: 100%;
}
.reel-show-card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  background-color: black;
}
